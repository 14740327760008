import React from 'react';
import { useModel, useForm, useFeatureFlags } from 'src/lib/hooks';
import {
  Button,
  Error,
  Form,
  Page,
  RadioInput,
  Select,
  TextArea,
} from 'src/common';
import {
  roomsDamaged,
  whoReferredYou,
  howOldIsTheSystem,
  causeOfLossQuestionsMapping,
} from 'src/lib/constants';

const Damages = ({ prevPage, nextPage }) => {
  const defaultValues = useModel.fnol.defaults();
  const { enableAdditionalCols } = useFeatureFlags();
  const { loss, causeOfLoss } = useModel.fnol();
  const {
    register,
    handleSubmit,
    watch,
    formError,
    formState: { errors, isSubmitting },
    control,
  } = useForm({
    shouldUnregister: true,
    defaultValues,
  });
  const wallsOrFloorsWet = watch('damages.areWallsFloorsWet');
  const waterMitigationCompany = watch(
    'damages.wasWaterMitigationCompanyContacted'
  );
  const wasAnybodyInjured = watch('damages.wasAnybodyInjured');
  const causeOfLossQuestions = causeOfLossQuestionsMapping[causeOfLoss];

  const propertyBeOccupied = watch('damages.canPropertyBeOccupied');
  const systemMalfunctioned = watch('damages.wasSystemOrApplianceMalfunction');

  const onSubmit = handleSubmit(async (data) => {
    await nextPage(data);
  });

  return (
    <Page
      stage="Tell Us About the Damages"
      subtitle="Please answer the following questions about the incident."
    >
      <Page.Section>
        {formError && <Error>{formError}</Error>}
        <Form onSubmit={onSubmit}>
          {causeOfLossQuestions?.needEmergencyTarping && (
            <RadioInput
              label="Do you need emergency tarping?"
              labelPosition="above"
              control={control}
              rules={{ required: 'Required' }}
              name="damages.isEmergencyTarpingNeeded"
              error={errors?.damages?.isEmergencyTarpingNeeded?.message}
            >
              <RadioInput.Option label="Yes" value="Yes" />
              <RadioInput.Option label="No" value="No" />
            </RadioInput>
          )}

          {causeOfLossQuestions?.needEmergencyBoardUp && (
            <RadioInput
              label="Do you need emergency board up?"
              labelPosition="above"
              control={control}
              rules={{ required: 'Required' }}
              name="damages.isEmergencyBoardUpNeeded"
              error={errors?.damages?.isEmergencyBoardUpNeeded?.message}
            >
              <RadioInput.Option label="Yes" value="Yes" />
              <RadioInput.Option label="No" value="No" />
            </RadioInput>
          )}
          {causeOfLossQuestions?.haveInteriorDamages && (
            <RadioInput
              label="Do you have interior damage?"
              labelPosition="above"
              control={control}
              rules={{ required: 'Required' }}
              name="damages.haveInteriorDamages"
              error={errors?.damages?.haveInteriorDamages?.message}
            >
              <RadioInput.Option label="Yes" value="Yes" />
              <RadioInput.Option label="No" value="No" />
              <RadioInput.Option label="Unknown" value="Unknown" />
            </RadioInput>
          )}
          {causeOfLossQuestions?.areWallsFloorsWet && (
            <RadioInput
              label="Are the walls or floors wet?"
              labelPosition="above"
              control={control}
              rules={{ required: 'Required' }}
              name="damages.areWallsFloorsWet"
              error={errors?.damages?.areWallsFloorsWet?.message}
            >
              <RadioInput.Option label="Yes" value="Yes" />
              <RadioInput.Option label="No" value="No" />
            </RadioInput>
          )}

          {causeOfLossQuestions?.waterMitigationCompanyContacted &&
            wallsOrFloorsWet === 'Yes' && (
              <RadioInput
                label="Has a water mitigation company already been contacted?"
                labelPosition="above"
                control={control}
                rules={{ required: 'Required' }}
                name="damages.wasWaterMitigationCompanyContacted"
                error={
                  errors?.damages?.wasWaterMitigationCompanyContacted?.message
                }
              >
                <RadioInput.Option label="Yes" value="Yes" />
                <RadioInput.Option label="No" value="No" />
              </RadioInput>
            )}
          {causeOfLossQuestions?.whoReferredProvider &&
            waterMitigationCompany === 'Yes' && (
              <Select
                label="Who referred you to this provider?"
                options={whoReferredYou}
                {...register('damages.whoReferredProvider', {
                  required: 'Required',
                })}
                error={errors?.damages?.whoReferredProvider?.message}
              />
            )}
          {causeOfLossQuestions?.areEmergencyWaterRemovalServicesNeeded &&
            waterMitigationCompany === 'No' && (
              <RadioInput
                label="Do you need emergency water removal or drying services?"
                labelPosition="above"
                control={control}
                rules={{ required: 'Required' }}
                name="damages.areEmergencyWaterRemovalServicesNeeded"
                error={
                  errors?.damages?.areEmergencyWaterRemovalServicesNeeded
                    ?.message
                }
              >
                <RadioInput.Option label="Yes" value="Yes" />
                <RadioInput.Option label="No" value="No" />
              </RadioInput>
            )}

          {causeOfLossQuestions?.numberOfRoomsDamaged && (
            <Select
              label="How many rooms were damaged?"
              options={roomsDamaged}
              {...register('damages.numberOfRoomsDamaged', {
                required: 'Required',
              })}
              error={errors?.damages?.numberOfRoomsDamaged?.message}
            />
          )}

          {/* TODO: to conditionally render this question since it depends on global state */}
          {causeOfLossQuestions?.canPropertyBeOccupied &&
            loss.location === 'Primary' && (
              <RadioInput
                label="Can the property be occupied?"
                labelPosition="above"
                control={control}
                rules={{ required: 'Required' }}
                name="damages.canPropertyBeOccupied"
                error={errors?.damages?.canPropertyBeOccupied?.message}
              >
                <RadioInput.Option label="Yes" value="Yes" />
                <RadioInput.Option label="No" value="No" />
              </RadioInput>
            )}
          {causeOfLossQuestions?.needHotelAccommodation &&
            propertyBeOccupied === 'No' && (
              <RadioInput
                label="Is a hotel accommodation needed?"
                labelPosition="above"
                control={control}
                rules={{ required: 'Required' }}
                name="damages.isHotelAccommodationNeeded"
                error={errors?.damages?.isHotelAccommodationNeeded?.message}
              >
                <RadioInput.Option label="Yes" value="Yes" />
                <RadioInput.Option label="No" value="No" />
              </RadioInput>
            )}
          {causeOfLossQuestions?.wasSystemOrApplianceMalfunction && (
            <RadioInput
              label="Was the loss the result of a system or appliance malfunction?"
              labelPosition="above"
              control={control}
              rules={{ required: 'Required' }}
              name="damages.wasSystemOrApplianceMalfunction"
              error={errors?.damages?.wasSystemOrApplianceMalfunction?.message}
            >
              <RadioInput.Option label="Yes" value="Yes" />
              <RadioInput.Option label="No" value="No" />
              <RadioInput.Option label="Unknown" value="Unknown" />
            </RadioInput>
          )}
          {causeOfLossQuestions?.howOldIsTheSystemMalfunctioned &&
            systemMalfunctioned === 'Yes' && (
              <Select
                label="How old is the system or appliance that malfunctioned?"
                options={howOldIsTheSystem}
                {...register('damages.howOldIsTheSystemMalfunctioned', {
                  required: 'Required',
                })}
                error={errors?.damages?.howOldIsTheSystemMalfunctioned?.message}
              />
            )}
          {causeOfLossQuestions?.wasAnyoneElsePropertyDamaged && (
            <RadioInput
              label="Was anyone else's property damaged?"
              labelPosition="above"
              control={control}
              rules={{ required: 'Required' }}
              name="damages.wasAnyoneElsePropertyDamaged"
              error={errors?.damages?.wasAnyoneElsePropertyDamaged?.message}
            >
              <RadioInput.Option label="Yes" value="Yes" />
              <RadioInput.Option label="No" value="No" />
              <RadioInput.Option label="Unknown" value="Unknown" />
            </RadioInput>
          )}
          {!!enableAdditionalCols && (
            <>
              {causeOfLossQuestions?.wasAnybodyInjured && (
                <RadioInput
                  label="Was anybody injured?"
                  labelPosition="above"
                  control={control}
                  rules={{ required: 'Required' }}
                  name="damages.wasAnybodyInjured"
                  error={errors?.damages?.wasAnybodyInjured?.message}
                >
                  <RadioInput.Option label="Yes" value="Yes" />
                  <RadioInput.Option label="No" value="No" />
                  <RadioInput.Option label="Unknown" value="Unknown" />
                </RadioInput>
              )}
              {wasAnybodyInjured === 'Yes' && (
                <>
                  {causeOfLossQuestions?.wasThisAFatality && (
                    <RadioInput
                      label="Was this a fatality?"
                      labelPosition="above"
                      control={control}
                      rules={{ required: 'Required' }}
                      name="damages.wasThisAFatality"
                      error={errors?.damages?.wasThisAFatality?.message}
                    >
                      <RadioInput.Option label="Yes" value="Yes" />
                      <RadioInput.Option label="No" value="No" />
                      <RadioInput.Option label="Unknown" value="Unknown" />
                    </RadioInput>
                  )}
                  {causeOfLossQuestions?.wasInjuredAMinor && (
                    <RadioInput
                      label="Was the person injured a minor?"
                      labelPosition="above"
                      control={control}
                      rules={{ required: 'Required' }}
                      name="damages.wasInjuredAMinor"
                      error={errors?.damages?.wasInjuredAMinor?.message}
                    >
                      <RadioInput.Option label="Yes" value="Yes" />
                      <RadioInput.Option label="No" value="No" />
                      <RadioInput.Option label="Unknown" value="Unknown" />
                    </RadioInput>
                  )}
                  {causeOfLossQuestions?.injuriesDescription && (
                    <TextArea
                      label="Describe the injuries"
                      maxLength={50}
                      minRows={4}
                      {...register('damages.injuriesDescription', {
                        required: 'Required',
                      })}
                      error={errors?.damages?.injuriesDescription?.message}
                    />
                  )}
                </>
              )}
            </>
          )}
          {causeOfLossQuestions?.additionalComments && (
            <TextArea
              label="Additional comments (optional)"
              maxLength={250}
              minRows={4}
              {...register('damages.additionalComments')}
              error={errors?.damages?.additionalComments?.message}
            />
          )}
        </Form>
      </Page.Section>
      <Page.Buttons>
        <Button
          color="water"
          onClick={prevPage}
          type="button"
          disabled={isSubmitting}
        >
          Go Back
        </Button>
        <Button
          color="navy"
          type="submit"
          onClick={onSubmit}
          loading={isSubmitting}
        >
          Next Step
        </Button>
      </Page.Buttons>
    </Page>
  );
};

Damages.displayName = 'Damages';

export default Damages;
