import config from 'src/config';

window.onbeforeunload = (e) => analytics.reset();

let analytics;
if (!config.segment.apiKey) {
  analytics = window.analytics = {
    identify: () => {},
    page: () => {},
    track: () => {},
    reset: () => {},
  };
} else {
  const defaultOptions = {
    callback: () => {},
  };
  analytics = {
    identify: (...args) => window.analytics.identify(...args),
    page: (...args) => window.analytics.page(...args),
    track: ({ name }, fnol, options = defaultOptions) => {
      const callback = options?.callback ?? defaultOptions.callback;

      const properties = {};
      if (fnol?.fnolId) {
        properties.fnolId = fnol.fnolId;
      }
      if (fnol?.loss?.address) {
        const { street, city, state, zip } = fnol.loss.address;
        properties.fnolAddress = `${street}, ${city}, ${state} ${zip}`;
      }
      if (fnol?.loss?.date) {
        properties.fnolDateOfLoss = fnol?.loss?.date;
      }
      properties.fnolUrl = window.location.origin;

      window.analytics.track(name, properties, callback);
    },
    reset: (...args) => {
      window.analytics.reset(...args);
      window.analytics.setAnonymousId('anonymous');
    },
  };
}

export default analytics;

export const EVENTS = {
  POLICY_VERIFIED: {
    name: 'policy verified',
  },
  CLAIM_ASSOCIATED: {
    name: 'claim associated',
  },
  CLAIM_SUBMITTED: {
    name: 'claim submitted',
  },
  RESUMED_CLAIM: {
    name: 'claim resumed',
  },
  CANCELLED_CLAIM: {
    name: 'claim cancelled',
  },
  LOSS_DETAILS_UPDATED: {
    name: 'loss details updated',
  },
  UNEXPECTED_ERROR: {
    name: 'unexpected error',
  },
};
