import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Loading } from 'src/common';
import { withPages } from 'src/lib/hoc';
import { useModel } from 'src/lib/hooks';
import {
  PolicyVerification,
  LossDetails,
  EmailVerification,
  Damages,
  UploadMedia,
  PreferredLanguage,
  Review,
  Submitted,
  ContactInfo,
} from 'src/pages';

const Pages = withPages([
  PolicyVerification,
  ContactInfo,
  EmailVerification.PolicyHolder,
  LossDetails,
  Damages,
  UploadMedia,
  PreferredLanguage,
  Review,
  Submitted,
]);

const Passwordless = () => {
  const { pendingChanges } = useModel.session();
  const fnol = useModel.fnol();
  const isDisclosureAcknowledged =
    pendingChanges?.isDisclosureAcknowledged === 'Yes' ||
    fnol?.isDisclosureAcknowledged === 'Yes';
  const history = useHistory();

  useEffect(() => {
    if (!isDisclosureAcknowledged && history.location.pathname === '/submit') {
      history.replace('/');
    }
  }, [isDisclosureAcknowledged, history]);
  if (!isDisclosureAcknowledged) {
    return <Loading />;
  }
  return <Pages />;
};

export default Passwordless;
