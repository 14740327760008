import React from 'react';
import { Button, Form, Input, Page } from 'src/common';
import { useAuth0, useForm, useFeatureFlags } from 'src/lib/hooks';
import EmailVerification from 'src/pages/EmailVerification';
import { validateEmail } from 'src/lib/validations';
import config from 'src/config';

import styles from './AuthMethods.module.css';

const AuthMethods = ({ handleContinue, handleStartNewClaim }) => {
  const { user } = useAuth0();
  const { enablePhcFnolIntegration } = useFeatureFlags();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    handleContinue(data.email);
  });

  return (
    <Page
      title="Resume Your Claim Submission"
      subtitle="We need verify your identity. Please provide an email to verify or log in with your Policyholder Center account."
    >
      {!user?.email ? (
        <>
          <Page.Section>
            <Form onSubmit={onSubmit}>
              <Form.Row>
                <Form.Col>
                  <Input
                    label="Email"
                    {...register('email', {
                      required: 'Required',
                      validate: validateEmail,
                    })}
                    error={errors?.email?.message}
                  />
                </Form.Col>
              </Form.Row>
              {enablePhcFnolIntegration && (
                <Form.Row>
                  <Form.Col>
                    <p className={styles.center}>or</p>
                    <br />
                    <Button
                      href={`${config.phc.baseUrl}/login?isPhc=true`}
                      variant="small"
                      color="transparent"
                      onClick={() => {}}
                      aria-label="Log in with Policyholder Center"
                      className={styles.link}
                    >
                      <span>Log in with</span>
                      <b>Policyholder Center</b>
                    </Button>
                  </Form.Col>
                </Form.Row>
              )}
            </Form>
          </Page.Section>
          <Page.Buttons>
            <Button color="water" onClick={handleStartNewClaim}>
              Start New Claim
            </Button>
            <Button
              color="navy"
              loading={isSubmitting}
              onClick={onSubmit}
              type="submit"
            >
              Continue
            </Button>
          </Page.Buttons>
        </>
      ) : (
        <EmailVerification.Resumption
          onBack={handleStartNewClaim}
          backTitle="Start New Claim"
        />
      )}
    </Page>
  );
};

export default AuthMethods;
