import React from 'react';
import { Route, Switch } from 'react-router';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import { Passwordless } from 'src/flows';
import { Cancel, Login, Resume, MaintenanceMode } from 'src/pages';
import config from 'src/config';
import { InitialDisclaimer } from './pages';
import { useFeatureFlags } from './lib/hooks';

const ldConfig = {
  clientSideID: config.launchDarkly.clientId,
  user: {
    key: 'anonymous',
    custom: { clientType: 'fnol' },
    anonymous: true,
  },
};

const App = () => {
  const { enableFnolMaintenanceMode } = useFeatureFlags();

  if (enableFnolMaintenanceMode) {
    return <MaintenanceMode />;
  }
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/resume" component={Resume} />
      <Route exact path="/cancel" component={Cancel} />
      <Route path="/submit" component={Passwordless} />
      <Route path="/" component={InitialDisclaimer} />
    </Switch>
  );
};

export default withLDProvider(ldConfig)(App);
