import { useState } from 'react';
import { Button, Page, Form, Error } from 'src/common';

import { scrollToTop } from 'src/lib/util';
import { useErrorHandling, useForm } from 'src/lib/hooks';
import Claim from './Claim';
import styles from './ResumeMultiple.module.css';

const ResumeMultiple = ({
  handleStartNewClaim,
  fnols,
  handleSelectedResumption,
}) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
    formError,
    setFormError,
  } = useForm();
  const [selectedFnol, setSelectedFnol] = useState();
  const handleError = useErrorHandling();

  const onSubmit = handleSubmit(async () => {
    setFormError();
    if (selectedFnol) {
      try {
        await handleSelectedResumption(selectedFnol);
      } catch (err) {
        scrollToTop();
        setFormError(await handleError(err));
      }
    }
  });

  return (
    <Page
      title="Your Open Claim Submissions"
      subtitle="Please selection the claim submission you wish to resume."
    >
      {!fnols?.length && (
        <Page.Section noCardStyle>
          <Error>
            There are no claim submissions in progress. Please start a new
            claim.
          </Error>
        </Page.Section>
      )}
      <Form onSubmit={onSubmit}>
        {formError && <Error>{formError}</Error>}
        <div className={styles.wrapper}>
          {!!fnols?.length &&
            fnols.map((fnol) => (
              <Claim
                key={fnol.fnolId}
                fnol={fnol}
                selected={selectedFnol}
                onSelect={setSelectedFnol}
              />
            ))}
        </div>
      </Form>
      <Page.Buttons>
        <Button onClick={handleStartNewClaim} color="water">
          Start New Claim
        </Button>
        {!!fnols?.length && (
          <Button
            onClick={onSubmit}
            color="navy"
            disabled={!selectedFnol}
            loading={isSubmitting}
          >
            Continue
          </Button>
        )}
      </Page.Buttons>
    </Page>
  );
};

export default ResumeMultiple;
