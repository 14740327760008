import React from 'react';
import styles from './Viewport.module.css';
import { Footer } from 'src/common';

const Viewport = ({ children }) => (
  <>
    <div className={styles.bgArch}>
      <main id="main-content" className={styles.main}>
        {children}
      </main>
    </div>
    <Footer />
  </>
);

export default Viewport;
