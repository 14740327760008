import React, { useCallback, useState } from 'react';
import { Button, Error, Form, Input, Page } from 'src/common';
import { useAuth0, useForm } from 'src/lib/hooks';
import styles from './Common.module.css';

const Common = ({ onBack, backTitle }) => {
  const {
    user,
    verifyCode,
    handleVerifyCodeError,
    startLogin,
    handleStartLoginError,
  } = useAuth0();
  const [sending, setSending] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    formState,
    formError,
    setFormError,
    watch,
  } = useForm({
    onError: async ({ err, setFormError, defaultOnError }) => {
      if (err?.error) {
        setFormError(handleVerifyCodeError(err));
      } else {
        await defaultOnError();
      }
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    await verifyCode(data.verificationCode.trim());
  });

  const resendCode = useCallback(async () => {
    setSending(true);
    try {
      await startLogin(user.email);
    } catch (err) {
      setFormError(handleStartLoginError(err));
    } finally {
      setSending(false);
    }
  }, [user?.email, startLogin, handleStartLoginError, setFormError]);

  const verificationCode = watch('verificationCode');
  return (
    <>
      <Page.Section>
        {formError && <Error>{formError}</Error>}
        <Form onSubmit={onSubmit}>
          <p className={styles.message}>
            Please enter the verification code we just sent to{' '}
            <b>{user?.email}</b>.
          </p>
          <Input
            label="Verification code"
            {...register('verificationCode', {
              required: 'Required',
            })}
            error={errors?.verificationCode?.message}
          />
          <div className={styles.resendMessage}>
            Didn't receive the code?
            <button
              className={styles.link}
              type="button"
              onClick={resendCode}
              disabled={formState.isSubmitting || sending}
            >
              {sending ? 'Sending...' : 'Resend'}
            </button>
          </div>
        </Form>
      </Page.Section>
      <Page.Buttons>
        {onBack && (
          <Button
            color="water"
            disabled={formState.isSubmitting}
            onClick={onBack}
            type="button"
          >
            {backTitle || 'Go Back'}
          </Button>
        )}
        <Button
          color="navy"
          onClick={onSubmit}
          type="submit"
          loading={formState.isSubmitting}
          disabled={!verificationCode}
        >
          Continue
        </Button>
      </Page.Buttons>
    </>
  );
};

export default Common;
