import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useController } from 'react-hook-form';
import classNames from 'tailwindcss-classnames';

import { acceptedFileTypes } from 'src/lib/constants';
import config from 'src/config';
import { FilePreview } from 'src/common';
import styles from './FileInput.module.css';

const accept = acceptedFileTypes.map((type) => type.mime);

const getFileSizeInMB = (bytes, decimals = 2) => {
  const k = 1024;
  const d = decimals < 0 ? 0 : decimals;
  return parseFloat((bytes / Math.floor(k * k)).toFixed(d));
};

const isTooBig = (file) => {
  const videoFileType = file.type.match(/(video\/\w*)/g);
  const size = getFileSizeInMB(file.size);
  const toCompareSize = !!videoFileType
    ? config.files.videoSize
    : config.files.otherSize;
  return size > toCompareSize;
};

const FileInput = ({
  name,
  control,
  error,
  rules,
  defaultValue = [],
  disabled = false,
  onDelete,
  getValues,
  watch,
  ...props
}) => {
  const {
    field: { onChange, value: files, ref },
  } = useController({ name, control, rules, defaultValue });

  watch(name);
  const { getRootProps, getInputProps, isDragAccept, isDragReject, rootRef } =
    useDropzone({
      accept,
      onDropAccepted: (incomingAcceptedFiles) => {
        const acceptedFiles = mapDroppedFiles(incomingAcceptedFiles, true);
        onChange(acceptedFiles);
      },
      onDropRejected: (incomingRejectedFiles) => {
        const rejectedFiles = mapDroppedFiles(
          incomingRejectedFiles.map(({ file }) => file),
          false
        );
        onChange(rejectedFiles);
      },
    });

  const mapDroppedFiles = (incomingFiles, isSupported) => [
    ...getValues(name),
    ...incomingFiles
      .map((f) => ({
        fileName: f.path,
        isSupported,
        isTooBig: isTooBig(f),
        file: f,
      }))
      .filter((m) => !files.find((f) => f.fileName === m.fileName)),
  ];

  const onDeleteFile = async (file, idx) => {
    if (file.fileUrl) {
      await onDelete(file, idx);
    }
    onChange(files.filter((f) => f.fileName !== file.fileName));
  };

  const className = classNames(styles.base, {
    [styles.accept]: isDragAccept,
    [styles.reject]: isDragReject || error,
  });

  return (
    <>
      <div
        {...getRootProps({
          className,
          ref: (e) => {
            ref(e);
            rootRef.current = e;
          },
        })}
      >
        <input
          {...props}
          {...getInputProps({
            name,
            disabled,
            style: {},
            className: 'sr-only',
          })}
          aria-invalid={error ? 'true' : 'false'}
          {...(error && { 'aria-describedby': `${name}-error` })}
        />
        <p>Drag and drop or click to select files</p>
        {error && (
          <div className={styles.supportedFilesContent}>
            <span id={`${name}-error`}>
              <strong>{error}</strong>
            </span>
          </div>
        )}
      </div>
      <FilePreview
        files={files}
        enableDelete={!disabled}
        onDeleteFile={onDeleteFile}
      />
    </>
  );
};

export default FileInput;
