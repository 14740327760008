import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import {
  AutocompleteAddress,
  Button,
  Error,
  Form,
  Icon,
  Input,
  Page,
} from 'src/common';
import { useForm, useModel } from 'src/lib/hooks';
import { timeTransform } from 'src/lib/transforms';
import {
  validateNotFutureDate,
  validatePolicyNumber,
} from 'src/lib/validations';
import Guidance from './Guidance';

import classNames from 'tailwindcss-classnames';
import styles from './PolicyVerification.module.css';

const PolicyVerification = ({ nextPage }) => {
  const [showGuidance, setShowGuidance] = useState(false);
  const toggleGuidance = () => setShowGuidance(!showGuidance);
  const todayDate = dayjs().format('YYYY-MM-DD');
  const { verifyPolicy } = useModel.fnol.dispatch();
  const [policyNotFound, setPolicyNotFound] = useState(false);
  const form = useForm();

  const {
    watch,
    register,
    handleSubmit,
    formError,
    setFormError,
    formState: { errors, isSubmitting },
  } = form;

  const policyHolderState = watch('policyHolder.address.state');
  const policyNumber = watch('policyNumber');
  const policyHolderAddress = watch('policyHolder.address');
  const policyNumberContainer = useRef(null);

  useEffect(() => {
    if (policyNumberContainer.current) {
      policyNumberContainer.current.querySelector('input').focus();
    }
  }, [formError]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (!data.loss.time) {
        delete data.loss['time'];
      }

      delete data?.policyHolder?.address?.apt;

      if (!data.policyHolder.address) {
        data.policyHolder.address = {
          street: '',
          city: '',
          state: '',
          zip: '',
        };
      } else if (!data.policyNumber) {
        delete data.policyNumber;
      }
      await verifyPolicy(data);
      await nextPage();
    } catch (error) {
      if (error?.response?.status === 404) {
        if (policyNotFound && !data.policyNumber) {
          throw error;
        } else {
          setPolicyNotFound(true);
          throw error;
        }
      } else {
        throw error;
      }
    }
  });

  return (
    <Page
      stage="Policy Verification"
      subtitle="Before we continue, we need to verify you have a valid policy with us. Please provide us with the insured property address and the date of the incident."
    >
      <Page.Section>
        {formError && <Error>{formError}</Error>}
        <Form onSubmit={onSubmit}>
          <Form.Row>
            <Form.Col>
              <Input
                type="date"
                label="Date of Incident"
                {...register('loss.date', {
                  required: 'Required',
                  validate: validateNotFutureDate,
                })}
                max={todayDate}
                error={errors?.loss?.date?.message}
              />
            </Form.Col>
            <Form.Col>
              <Input
                type="time"
                label="Incident Time (optional)"
                optional
                {...register('loss.time', {
                  setValueAs: timeTransform.output,
                })}
                error={errors?.loss?.time?.message}
              />
            </Form.Col>
          </Form.Row>
          {policyNotFound && <hr className={styles.divider} />}
          <Form.Row>
            <AutocompleteAddress
              name="policyHolder.address"
              label="Insured Property"
              optional={!!policyNumber}
              {...form}
            />
          </Form.Row>
          {policyNotFound && (
            <div ref={policyNumberContainer}>
              <p className={styles.conditional}>or</p>
              <Input
                label={
                  <div className={styles.buttonContainer}>
                    Policy Number
                    <Button
                      aria-label="Policy Number Help"
                      onClick={toggleGuidance}
                      color="transparent"
                      type="button"
                      className={styles.button}
                    >
                      <Icon
                        name="questionCircle"
                        size={20}
                        className={classNames(styles.icon)}
                      />
                    </Button>
                  </div>
                }
                {...register('policyNumber', {
                  validate: (value) => !value || validatePolicyNumber(value),
                  required: {
                    value:
                      !policyHolderAddress?.street ||
                      !policyHolderAddress?.city ||
                      !policyHolderAddress?.state ||
                      !policyHolderAddress?.zip,
                    message: 'Required',
                  },
                })}
                data-testid="policyNumber"
                error={errors?.policyNumber?.message}
              />
            </div>
          )}
        </Form>
      </Page.Section>
      <Page.Buttons>
        <Button color="navy" onClick={onSubmit} loading={isSubmitting}>
          Next Step
        </Button>
      </Page.Buttons>
      {showGuidance && (
        <Guidance
          defaultState={policyHolderState}
          visible={showGuidance}
          toggle={toggleGuidance}
        />
      )}
    </Page>
  );
};

PolicyVerification.displayName = 'PolicyVerification';

export default PolicyVerification;
