import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useModel } from 'src/lib/hooks';
import { useHistory } from 'react-router-dom';
import { Page, Form, Checkbox, Button, InfoBox } from 'src/common';
import styles from './InitialDisclaimer.module.css';
import analytics from 'src/lib/analytics';

const InitialDisclaimer = () => {
  const session = useModel.session();
  const history = useHistory();
  const { register, watch, handleSubmit } = useForm();
  const { stageChanges } = useModel.session.dispatch();

  useEffect(() => {
    if (session.page) {
      history.replace('/submit');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    analytics.page('InitialDisclaimer');
  }, []);

  const submit = handleSubmit(async (data) => {
    if (data.isDisclosureAcknowledged) {
      data.isDisclosureAcknowledged = 'Yes';
      stageChanges(data);
      history.replace('/submit');
    }
  });

  const isDisclosureAcknowledged = watch('isDisclosureAcknowledged');

  return (
    <Page title="Important Information Before You Begin">
      <Page.Section>
        <div className={styles.content}>
          <p>
            You will be guided through a short process that collects information
            about your property damage claim. While completing this form you
            will have the ability to provide photographs and video of your loss.
            However, photographs and video are NOT required to complete your
            claim submission.
            <br />
            The safety of you and your family is of the utmost importance for
            us.
          </p>
          <p>
            PLEASE COMPLETE ONLY THE STEPS IN THIS PROCESS THAT YOU CAN COMPLETE
            WITHOUT RISK OF PERSONAL INJURY OR PROPERTY DAMAGE. DO NOT ENTER ANY
            PART OF YOUR PROPERTY IF YOU DO NOT FEEL SAFE DOING SO. IF YOU ARE
            TAKING PHOTOGRAPHS OR VIDEO, PLEASE DO SO ONLY FROM A SAFE AREA.
            PLEASE DO NOT CLIMB ON YOUR ROOF TO TAKE PHOTOGRAPHS OR VIDEO OF
            DAMAGE.
          </p>
          <p>
            We are not responsible for any injury or damage that occurs while
            you are taking photographs or video for your claim submission.
          </p>
          <p>
            Please be aware that your mobile provider may charge you for data
            usage if you are not connected to a wireless Internet network.
          </p>
          <p>
            By checking the box below, you acknowledge that you have read and
            understood the above statement and you release us from all claims
            for property damage or personal injury incurred during or in
            connection with the completion of this claim submission.
          </p>
        </div>
        <Form onSubmit={submit}>
          <Checkbox
            containerClassName={styles.checkbox}
            label="I have read and understood the above statement"
            {...register('isDisclosureAcknowledged')}
          />
        </Form>
      </Page.Section>
      <Page.Buttons>
        <Button
          type="submit"
          color="navy"
          disabled={!isDisclosureAcknowledged}
          onClick={submit}
        >
          Start my Claim
        </Button>
      </Page.Buttons>
    </Page>
  );
};

InitialDisclaimer.displayName = 'InitialDisclaimer';

export default InitialDisclaimer;
