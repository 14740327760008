import React from 'react';
import { Button, Error, Form, Page, RadioInput, Select } from 'src/common';
import { languages } from 'src/lib/constants';
import { useModel, useForm } from 'src/lib/hooks';

const PreferredLanguage = ({ prevPage, nextPage }) => {
  const {
    areTranslationServicesNeeded: defaultNeedTranslationServices,
    preferredLanguage: defaultPreferredLanguage,
  } = useModel.fnol.defaults();
  const {
    control,
    register,
    handleSubmit,
    watch,
    formError,
    formState: { errors, isSubmitting },
  } = useForm({
    shouldUnregister: true,
    defaultValues: {
      areTranslationServicesNeeded: defaultNeedTranslationServices,
      preferredLanguage: defaultPreferredLanguage,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    await nextPage({
      preferredLanguage: data.preferredLanguage ?? 'English',
      areTranslationServicesNeeded,
    });
  });

  const areTranslationServicesNeeded = watch('areTranslationServicesNeeded');
  const preferredLanguage = watch('preferredLanguage');

  return (
    <Page
      stage="Preferred Language"
      subtitle="After you've submitted your claim, the adjuster will contact you. Please provide a preferred language."
    >
      <Page.Section>
        {formError && <Error>{formError}</Error>}
        <Form onSubmit={onSubmit}>
          <RadioInput
            control={control}
            name="areTranslationServicesNeeded"
            label="Do you need translation services to effectively communicate with your adjuster?"
            error={errors?.areTranslationServicesNeeded?.message}
            rules={{ required: 'Required' }}
            labelPosition="above"
          >
            <RadioInput.Option label="Yes" value="Yes" />
            <RadioInput.Option label="No" value="No" />
          </RadioInput>
          {areTranslationServicesNeeded === 'Yes' && (
            <Select
              label="Select Your Preferred Language"
              error={errors?.preferredLanguage?.message}
              options={languages}
              {...register('preferredLanguage', { required: 'Required' })}
            />
          )}
        </Form>
      </Page.Section>
      <Page.Buttons>
        <Button
          type="button"
          color="water"
          onClick={prevPage}
          disabled={isSubmitting}
        >
          Go Back
        </Button>
        <Button
          color="navy"
          onClick={onSubmit}
          loading={isSubmitting}
          disabled={
            (areTranslationServicesNeeded === 'Yes' && !preferredLanguage) ||
            areTranslationServicesNeeded === undefined
          }
        >
          Next Step
        </Button>
      </Page.Buttons>
    </Page>
  );
};

PreferredLanguage.displayName = 'PreferredLanguage';

export default PreferredLanguage;
