import classNames from 'tailwindcss-classnames';

import styles from './ResumeMultiple.module.css';

const zipFormat = (zip) => {
  let zipCode = '';
  if (zip) {
    zipCode = zip.match(/\b\d{5}\b/);
  }
  return zipCode;
};

const addressFormat = ({ street, city, state, zip }) =>
  `${street}, ${city}, ${state} ${zipFormat(zip)}`;

const Claim = ({ fnol, selected, onSelect }) => {
  const isSelected = fnol.fnolId === selected;
  const content = (
    <>
      <div>
        <b>{addressFormat(fnol.loss.address)}</b>
      </div>
      <div>
        <hr
          className={classNames('my-2', {
            'border-warm-gray': !isSelected,
            'border-white': isSelected,
          })}
        />
        <div>
          <b>Date: </b>
          <span>{fnol.loss.date}</span>
        </div>
        {fnol.causeOfLoss && (
          <div>
            <b>Cause: </b>
            <span>{fnol.causeOfLoss}</span>
          </div>
        )}
      </div>
    </>
  );
  return (
    <div className={styles.cardWrapper}>
      <label
        className={classNames(styles.card, {
          'bg-white': !isSelected,
          'bg-navy text-white': isSelected,
        })}
      >
        <input
          type="radio"
          name="openFnol"
          onChange={() => onSelect(fnol.fnolId)}
          className="sr-only"
          aria-checked={isSelected ? 'true' : 'false'}
        />
        {content}
      </label>
    </div>
  );
};

export default Claim;
