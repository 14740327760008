import {
  gStateFlHi,
  gStateFlHi2,
  gStateFlHi3,
  gStateGaNcScTx,
  gStateGaNcScTx2,
  gStateLa,
  gStateLa2,
  gStateLa3,
  gStateNy,
  gStateNy2,
  gStateNy3,
} from 'src/assets/img';

const lossMapping = {
  types: [
    {
      id: 'Weather Event',
      title: 'Weather Event',
    },
    {
      id: 'Water',
      title: 'Water',
    },
    {
      id: 'Other Property Damage',
      title: 'Other Property Damage',
    },
    {
      id: 'Fire',
      title: 'Fire',
    },
    {
      id: 'Liability',
      title: 'Liability',
    },
    {
      id: 'Malicious Acts',
      title: 'Malicious Acts',
    },
  ],
  causes: {
    'Weather Event': [
      { id: 'Flood', title: 'Flood' },
      { id: 'Hail', title: 'Hail' },
      { id: 'Hurricane', title: 'Hurricane' },
      { id: 'Tropical Storm', title: 'Tropical Storm' },
      { id: 'Freezing Ice Dam', title: 'Freezing Ice Dam' },
      { id: 'Lightning', title: 'Lightning' },
      { id: 'Roof', title: 'Roof' },
      { id: 'Sinkhole', title: 'Sinkhole' },
      { id: 'Tornado', title: 'Tornado' },
      {
        id: 'Weight of Ice or Snow',
        title: 'Weight of Ice or Snow',
      },
      {
        id: 'Windstorm Other than Hurricane',
        title: 'Windstorm Other than Hurricane',
      },
    ],
    'Water': [
      { id: 'Freezing Pipes', title: 'Freezing Pipes' },
      { id: 'Freezing Slab', title: 'Freezing Slab' },
      { id: 'Mold', title: 'Mold' },
      { id: 'Plumbing/Appliance Leak', title: 'Plumbing/Appliance Leak' },
      { id: 'Sewer Backup', title: 'Sewer Backup' },
      { id: 'Overflow', title: 'Overflow' },
    ],
    'Other Property Damage': [
      { id: 'Collision', title: 'Collision' },
      { id: 'Equipment Breakdown', title: 'Equipment Breakdown' },
      { id: 'Explosion', title: 'Explosion' },
      { id: 'Falling Object', title: 'Falling Object' },
      { id: 'Food Spoilage', title: 'Food Spoilage' },
      { id: 'Insect/Vermin/Animal', title: 'Insect/Vermin/Animal' },
      { id: 'Loss Assessment', title: 'Loss Assessment' },
      { id: 'Mandatory Evacuation', title: 'Mandatory Evacuation' },
      { id: 'Other', title: 'Other' },
      { id: 'Power Surge/Failure', title: 'Power Surge/Failure' },
      { id: 'Settling/Cracking', title: 'Settling/Cracking' },
    ],
    'Fire': [
      { id: 'Fire', title: 'Fire' },
      { id: 'Grease Fire', title: 'Grease Fire' },
      { id: 'Smoke and Soot', title: 'Smoke and Soot' },
    ],
    'Liability': [
      { id: 'Animal', title: 'Animal' },
      { id: 'Bodily Injury', title: 'Bodily Injury' },
      { id: 'Other Liability', title: 'Other Liability' },
      { id: 'Property Damage', title: 'Property Damage' },
    ],
    'Malicious Acts': [
      { id: 'Burglary', title: 'Burglary' },
      { id: 'Theft', title: 'Theft' },
      { id: 'Identity Theft/Fraud', title: 'Identity Theft/Fraud' },
      {
        id: 'Vandalism/Malicious Mischief',
        title: 'Vandalism/Malicious Mischief',
      },
    ],
  },
};

const lossLocations = [
  { id: 'Primary', title: 'Primary' },
  { id: 'Secondary', title: 'Secondary' },
  { id: 'Rental Property', title: 'Rental Property' },
  { id: 'Off Premises', title: 'Off Premises' },
];

const lossReporterRoles = [
  { id: 'Policyholder', title: 'Policyholder' },
  { id: 'Additional Insured', title: 'Additional Insured' },
  { id: 'Agent', title: 'Agent' },
  { id: 'Animal Owner', title: 'Animal Owner' },
  { id: 'Assignment of Benefits', title: 'Assignment of Benefits' },
  { id: 'Attorney', title: 'Attorney' },
  { id: 'Beneficiary', title: 'Beneficiary' },
  { id: 'Claimant', title: 'Claimant' },
  { id: 'Contractor / Repair', title: 'Contractor / Repair' },
  { id: 'Driver', title: 'Driver' },
  {
    id: 'Emergency Service/Water Mitigation',
    title: 'Emergency Service/Water Mitigation',
  },
  { id: 'Employee', title: 'Employee' },
  { id: 'Estate/Trustee', title: 'Estate/Trustee' },
  { id: 'Insurance Company', title: 'Insurance Company' },
  { id: 'Mortgagee', title: 'Mortgagee' },
  { id: 'Other Carrier', title: 'Other Carrier' },
  { id: 'Other Representative Entity', title: 'Other Representative Entity' },
  { id: 'Parent/Guardian', title: 'Parent/Guardian' },
  { id: 'Power of Attorney', title: 'Power of Attorney' },
  { id: 'Property Manager', title: 'Property Manager' },
  { id: 'Public Adjuster', title: 'Public Adjuster' },
  { id: 'Recovery Counsel', title: 'Recovery Counsel' },
  { id: 'Responsible Party', title: 'Responsible Party' },
  { id: 'Responsible Party Attorney', title: 'Responsible Party Attorney' },
  { id: 'Responsible Party Carrier', title: 'Responsible Party Carrier' },
  { id: 'Witness', title: 'Witness' },
];

const languages = [
  {
    id: 'Spanish',
    title: 'Spanish',
  },
  {
    id: 'English',
    title: 'English',
  },
  {
    id: 'French',
    title: 'French',
  },
  {
    id: 'Creole',
    title: 'Creole',
  },
  {
    id: 'Chinese',
    title: 'Chinese',
  },
  {
    id: 'Mandarin',
    title: 'Mandarin',
  },
  {
    id: 'Cantonese',
    title: 'Cantonese',
  },
  {
    id: 'Arabic',
    title: 'Arabic',
  },
  {
    id: 'German',
    title: 'German',
  },
  {
    id: 'Russian',
    title: 'Russian',
  },
  {
    id: 'Hindi',
    title: 'Hindi',
  },
  {
    id: 'Portuguese',
    title: 'Portuguese',
  },
  {
    id: 'Vietnamese',
    title: 'Vietnamese',
  },
  {
    id: 'Italian',
    title: 'Italian',
  },
  {
    id: 'Korean',
    title: 'Korean',
  },
  {
    id: 'Japanese',
    title: 'Japanese',
  },
  {
    id: 'Other',
    title: 'Other',
  },
];

const whoReferredYou = [
  { id: 'Friend/Relative', title: 'Friend/Relative' },
  { id: 'Advertising', title: 'Advertising' },
  { id: 'Public Adjuster', title: 'Public Adjuster' },
  { id: 'Attorney', title: 'Attorney' },
  { id: 'Plumber/Contractor', title: 'Plumber/Contractor' },
];

const howOldIsTheSystem = [
  { id: 'Less than 10 years old', title: 'Less than 10 years old' },
  { id: 'Greater than 10 years old', title: 'Greater than 10 years old' },
  { id: 'Unknown', title: 'Unknown' },
];

const roomsDamaged = [
  { id: 'None', title: 'None' },
  { id: '1-2', title: '1-2' },
  { id: '3-6', title: '3-6' },
  { id: '7-10', title: '7-10' },
  { id: 'All', title: 'All' },
];

const acceptedFileTypes = [
  { ext: 'jpg', mime: 'image/jpg', icon: 'fileImage' },
  { ext: 'jpeg', mime: 'image/jpeg', icon: 'fileImage' },
  { ext: 'png', mime: 'image/png', icon: 'fileImage' },
  { ext: 'mp4', mime: 'video/mp4', icon: 'fileVideo' },
  { ext: 'mov', mime: 'video/quicktime', icon: 'fileVideo' },
  { ext: 'wmv', mime: 'video/x-ms-wmv', icon: 'fileVideo' },
  { ext: 'avi', mime: 'video/avi', icon: 'fileVideo' },
  { ext: 'wav', mime: 'audio/wav', icon: 'fileAudio' },
  { ext: 'mp3', mime: 'audio/mpeg', icon: 'fileAudio' },
  {
    ext: 'docx',
    mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    icon: 'fileWord',
  },
  {
    ext: 'xlsx',
    mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    icon: 'fileExcel',
  },
  { ext: 'pdf', mime: 'application/pdf', icon: 'filePdf' },
];

const commonStateFlHi = [
  { path: gStateFlHi, name: 'imgFlHi' },
  { path: gStateFlHi2, name: 'imgFlHi2' },
  { path: gStateFlHi3, name: 'imgFlHi3' },
];

const commonStateGaNcScTx = [
  { path: gStateGaNcScTx, name: 'imgGaNcScTx' },
  { path: gStateGaNcScTx2, name: 'imgGaNcScTx2' },
];

const guidanceImagesStates = {
  FL: {
    title: 'Florida',
    images: commonStateFlHi,
  },
  HI: {
    title: 'Hawaii',
    images: commonStateFlHi,
  },
  GA: {
    title: 'Georgia',
    images: commonStateGaNcScTx,
  },
  NC: {
    title: 'North Carolina',
    images: commonStateGaNcScTx,
  },
  SC: {
    title: 'South Carolina',
    images: commonStateGaNcScTx,
  },
  TX: {
    title: 'Texas',
    images: commonStateGaNcScTx,
  },
  LA: {
    title: 'Louisiana',
    images: [
      { path: gStateLa, name: 'imgLa' },
      { path: gStateLa2, name: 'imgLa2' },
      { path: gStateLa3, name: 'imgLa3' },
    ],
  },
  NY: {
    title: 'New York',
    images: [
      { path: gStateNy, name: 'imgNy' },
      { path: gStateNy2, name: 'imgNy2' },
      { path: gStateNy3, name: 'imgNy3' },
    ],
  },
};

const unsupportedFile = {
  ext: '*',
  mime: 'unsupported/*',
  icon: 'fileUnsupported',
};

const causeOfLossQuestionsMapping = {
  'Flood': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    wasAnyoneElsePropertyDamaged: true,
  },
  'Hail': {
    needEmergencyTarping: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
  },
  'Hurricane': {
    needEmergencyTarping: true,
    needEmergencyBoardUp: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
  },
  'Tropical Storm': {
    needEmergencyTarping: true,
    needEmergencyBoardUp: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
  },
  'Freezing Ice Dam': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
  },
  'Lightning': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
  },
  'Roof': {
    needEmergencyTarping: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
  },
  'Sinkhole': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
  },
  'Tornado': {
    needEmergencyTarping: true,
    needEmergencyBoardUp: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
  },
  'Weight of Ice or Snow': {
    needEmergencyTarping: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
  },
  'Windstorm Other than Hurricane': {
    needEmergencyTarping: true,
    needEmergencyBoardUp: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
  },
  'Freezing Pipes': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
  },
  'Freezing Slab': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    additionalComments: true,
  },
  'Mold': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
  },
  'Plumbing/Appliance Leak': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
  },
  'Sewer Backup': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
  },
  'Overflow': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
  },
  'Collision': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  'Equipment Breakdown': {
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    numberOfRoomsDamaged: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    additionalComments: true,
  },
  'Explosion': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  'Falling Object': {
    needEmergencyTarping: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    additionalComments: true,
  },
  'Food Spoilage': {
    haveInteriorDamages: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    additionalComments: true,
  },
  'Insect/Vermin/Animal': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    additionalComments: true,
  },
  'Loss Assessment': { haveInteriorDamages: true, additionalComments: true },
  'Mandatory Evacuation': {
    haveInteriorDamages: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
    additionalComments: true,
  },
  'Other': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    additionalComments: true,
  },
  'Power Surge/Failure': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    additionalComments: true,
  },
  'Settling/Cracking': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    additionalComments: true,
  },
  'Fire': {
    needEmergencyBoardUp: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  'Grease Fire': {
    needEmergencyBoardUp: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  'Smoke and Soot': {
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    needHotelAccommodation: true,
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  'Animal': {
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  'Bodily Injury': {
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  'Other Liability': {
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  'Property Damage': {
    wasSystemOrApplianceMalfunction: true,
    howOldIsTheSystemMalfunctioned: true,
    wasAnyoneElsePropertyDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  'Burglary': {
    needEmergencyBoardUp: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  'Theft': {
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,

    additionalComments: true,
  },
  'Identity Theft/Fraud': {
    additionalComments: true,
  },
  'Vandalism/Malicious Mischief': {
    needEmergencyBoardUp: true,
    haveInteriorDamages: true,
    numberOfRoomsDamaged: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
    additionalComments: true,
  },
  '_testAll': {
    needEmergencyTarping: true,
    needEmergencyBoardUp: true,
    haveInteriorDamages: true,
    areWallsFloorsWet: true,
    numberOfRoomsDamaged: true,
    canPropertyBeOccupied: true,
    wasSystemOrApplianceMalfunction: true,
    wasAnyoneElsePropertyDamaged: true,
    waterMitigationCompanyContacted: true,
    whoReferredProvider: true,
    needHotelAccommodation: true,
    howOldIsTheSystemMalfunctioned: true,
    additionalComments: true,
    areEmergencyWaterRemovalServicesNeeded: true,
    wasAnybodyInjured: true,
    wasThisAFatality: true,
    wasInjuredAMinor: true,
    injuriesDescription: true,
  },
};
const generalCard = {
  firstName: 'First Name',
  lastName: 'Last Name',
  phone: 'Phone',
  email: 'Email',
};

const companyCard = {
  ...generalCard,
  companyName: { label: 'Company Name', key: 'companyName' },
};

const attorneyCard = {
  ...generalCard,
  firstName: 'Attorney First Name',
  lastName: 'Attorney Last Name',
  companyName: { label: 'Firm Name', key: 'firmName' },
};

const insuranceCard = {
  ...companyCard,
  accountNumber: {
    label: 'Adverse Carrier Claim #',
    key: 'adverseCarrierClaimNumber',
  },
  clientFirstName: {
    label: 'Adverse First Name',
    key: 'adverseClientFirstName',
  },
  clientLastName: {
    label: 'Adverse Last Name',
    key: 'adverseClientLastName',
  },
};

const mortgageeCard = {
  ...companyCard,
  accountNumber: {
    label: 'Mortgagee Account #',
    key: 'mortgageeAccountNumber',
  },
  clientFirstName: {
    label: 'Client First Name',
    key: 'adverseClientFirstName',
  },
  clientLastName: {
    label: 'Client Last Name',
    key: 'adverseClientLastName',
  },
};

const contactFields = {
  'Additional Insured': generalCard,
  'Agent': generalCard,
  'Animal Owner': generalCard,
  'Assignment of Benefits': companyCard,
  'Attorney': attorneyCard,
  'Beneficiary': generalCard,
  'Claimant': generalCard,
  'Contractor / Repair': companyCard,
  'Driver': generalCard,
  'Emergency Service/Water Mitigation': companyCard,
  'Employee': companyCard,
  'Estate/Trustee': generalCard,
  'Insurance Company': insuranceCard,
  'Mortgagee': mortgageeCard,
  'Other Carrier': insuranceCard,
  'Other Representative Entity': attorneyCard,
  'Parent/Guardian': generalCard,
  'Power of Attorney': generalCard,
  'Property Manager': companyCard,
  'Public Adjuster': companyCard,
  'Recovery Counsel': attorneyCard,
  'Responsible Party': generalCard,
  'Responsible Party Attorney': attorneyCard,
  'Responsible Party Carrier': insuranceCard,
  'Witness': generalCard,
};

export {
  lossMapping,
  lossReporterRoles,
  languages,
  lossLocations,
  whoReferredYou,
  roomsDamaged,
  howOldIsTheSystem,
  acceptedFileTypes,
  guidanceImagesStates,
  unsupportedFile,
  causeOfLossQuestionsMapping,
  contactFields,
};
