import React, { useEffect } from 'react';
import merge from 'lodash.merge';
import {
  Select,
  Button,
  Page,
  TextArea,
  Checkbox,
  Form,
  Icon,
  Modal,
  Error,
  AutocompleteAddress,
} from 'src/common';
import { useModel, useForm, useToggle, useFeatureFlags } from 'src/lib/hooks';
import {
  lossMapping,
  lossLocations,
  causeOfLossQuestionsMapping,
} from 'src/lib/constants';
import styles from './LossDetails.module.css';
import analytics, { EVENTS } from 'src/lib/analytics';

const LossDetails = ({ nextPage, isHistorical }) => {
  const fnol = useModel.fnol();
  const defaultValues = useModel.fnol.defaults();
  const { pendingChanges } = useModel.session();
  const defaultSamePolicyAddress =
    JSON.stringify(defaultValues?.loss?.address) ===
    JSON.stringify(defaultValues?.policyHolder?.address);
  const form = useForm({
    defaultValues: {
      causeOfLoss: defaultValues.causeOfLoss,
      typeOfLoss: defaultValues.typeOfLoss,
      loss: {
        ...defaultValues.loss,
        address: defaultSamePolicyAddress ? null : defaultValues.loss.address,
      },
      samePolicyAddress: defaultSamePolicyAddress,
    },
    shouldUnregister: true,
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setFormError,
    formError,
    formState: { errors, isSubmitting },
  } = form;

  const samePolicyAddress = watch('samePolicyAddress');
  const [lossModalOpen, toggleLossModalOpen] = useToggle(false);
  const { enableAdditionalCols, enableLiabilityCols } = useFeatureFlags();

  const onSubmit = handleSubmit(async (data) => {
    setFormError(null);
    const causeOfLossQuestions = causeOfLossQuestionsMapping[data?.causeOfLoss];
    if (
      (causeOfLossQuestions.wasAnybodyInjured ||
        causeOfLossQuestions.wasThisAFatality ||
        causeOfLossQuestions.wasInjuredAMinor ||
        causeOfLossQuestions.injuriesDescription) &&
      !enableAdditionalCols
    ) {
      setFormError(
        `We don't currently support online submissions for ${causeOfLoss} claims. 
        Please contact the UPC Insurance Customer Experience Center (CEC) at (800) 557-0964 for assistance.`
      );
    } else {
      if (data.samePolicyAddress && isHistorical) {
        data.loss.address = defaultValues.policyHolder.address;
      } else if (data.samePolicyAddress) {
        delete data.loss.address;
      }
      delete data.samePolicyAddress;
      await nextPage(merge({}, pendingChanges, data));
      if (isHistorical) {
        analytics.track(EVENTS.LOSS_DETAILS_UPDATED, { ...fnol, ...data });
      } else {
        analytics.track(EVENTS.CLAIM_ASSOCIATED, { ...fnol, ...data });
      }
    }
  });

  const typeOfLoss = watch('typeOfLoss');
  const causeOfLoss = watch('causeOfLoss');

  useEffect(() => {
    if (
      (typeOfLoss && !defaultValues?.causeOfLoss) ||
      typeOfLoss !== defaultValues?.typeOfLoss ||
      (typeOfLoss === defaultValues?.typeOfLoss &&
        causeOfLoss !== defaultValues?.causeOfLoss)
    ) {
      setValue('causeOfLoss', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeOfLoss, setValue]);

  useEffect(() => {
    if (!enableLiabilityCols && typeOfLoss === 'Liability') {
      setValue('typeOfLoss', '');
      setValue('causeOfLoss', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableLiabilityCols]);

  return (
    <Page
      stage="Tell Us About Your Incident"
      subtitle="Please provide us with more details about the incident and where it occurred. Select a type and cause that best describes the incident."
    >
      <Page.Section>
        {formError && <Error>{formError}</Error>}
        <Form onSubmit={onSubmit}>
          <Form.Row>
            <Form.Col>
              <Select
                label="Incident Type"
                error={errors?.typeOfLoss?.message}
                options={
                  enableLiabilityCols
                    ? lossMapping?.types
                    : lossMapping.types.filter((tol) => tol.id !== 'Liability')
                }
                {...register('typeOfLoss', { required: 'Required' })}
              />
            </Form.Col>
            <Form.Col>
              <Select
                label="Cause of Incident"
                error={errors?.causeOfLoss?.message}
                options={typeOfLoss && lossMapping?.causes[typeOfLoss]}
                disabled={!typeOfLoss}
                {...register('causeOfLoss', { required: 'Required' })}
              />
            </Form.Col>
          </Form.Row>
          <Select
            label={
              <>
                Incident Location
                <button
                  className={styles.icon}
                  type="button"
                  onClick={toggleLossModalOpen}
                  aria-label="Incident Location Help"
                >
                  <Icon
                    aria-hidden
                    alt="Toggle selected"
                    name="exclamationCircle"
                    color="navy"
                    size={16}
                  />
                </button>
              </>
            }
            data-testid="lossLocation"
            options={lossLocations}
            {...register('loss.location', { required: 'Required' })}
            error={errors?.loss?.location?.message}
          />
          <TextArea
            label="Description of Incident"
            maxLength={400}
            minRows={4}
            {...register('loss.description', {
              required: 'Required',
            })}
            error={errors?.loss?.description?.message}
          />
          <div className={styles.locationOfLoss}>
            <span className={styles.label}>Incident Address</span>
            <Checkbox
              label="Same as insured property address"
              checked={!!samePolicyAddress}
              {...register('samePolicyAddress')}
              error={errors?.samePolicyAddress?.message}
            />
          </div>
          {!samePolicyAddress && (
            <AutocompleteAddress
              name="loss.address"
              isHistorical={isHistorical && !defaultSamePolicyAddress}
              {...form}
            />
          )}
        </Form>
      </Page.Section>
      <Page.Buttons>
        <Button
          color="navy"
          onClick={onSubmit}
          type="submit"
          loading={isSubmitting}
        >
          Next Step
        </Button>
      </Page.Buttons>
      {lossModalOpen && (
        <Modal
          showModal={lossModalOpen}
          enableCloseButton
          onClose={toggleLossModalOpen}
          ariaLabel="Incident Location"
          heading={<h2 className={styles.heading}>Incident Location</h2>}
        >
          <div className={styles.lossModal}>
            <p>
              <b>Primary:</b> Policyholder resides at this location full time or
              for more than 6 consecutive months of the year
            </p>

            <p>
              <b>Secondary:</b> This location is a vacation home, rental
              property, time share, etc.
            </p>

            <p>
              <b>Rental property:</b> Someone other than the policyholder
              resides at this location full time
            </p>

            <p>
              <b>Off premises:</b> Occurred at a location other than the address
              on the policy
            </p>
          </div>
        </Modal>
      )}
    </Page>
  );
};

LossDetails.displayName = 'LossDetails';

export default LossDetails;
