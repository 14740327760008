import isemail from 'isemail';
import dayjs from 'dayjs';
import config from 'src/config';

export const validateEmail = (value) => {
  if (!isemail.validate(value)) {
    return 'Invalid email';
  }

  return true;
};

export const validatePolicyNumber = (rawInput = '') => {
  const containsAlphanumericAndSpacesOnly = /^[0-9A-Za-z ]+$/.test(rawInput);
  const atLeast10Alphanumeric = rawInput.split(' ').join('').length >= 10;
  return (
    (containsAlphanumericAndSpacesOnly && atLeast10Alphanumeric) ||
    'Invalid policy number'
  );
};

export const validateNotFutureDate = (value) => {
  if (!value || value.length < 10) {
    return 'Invalid date';
  }
  const date = dayjs(value, 'YYYY-MM-DD');
  if (!date.isValid()) {
    return 'Invalid date';
  }

  if (date.isAfter(dayjs())) {
    return 'Future date not allowed';
  }

  return true;
};

export const validateFiles = (files) => {
  const anyUnsupported = files.some((f) => !f.isSupported);
  const anyTooBig = files.some((f) => f.isTooBig);
  let errors = [];

  if (anyTooBig || anyUnsupported) {
    errors.push('An uploaded file is not supported or is too big.');
  }

  if (files.length > config.files.maxNumber) {
    errors.push('Max files exceeded.');
  }

  if (!!errors.length) {
    return errors.join(' ');
  }

  return true;
};

export const validateNotEmpty = (value = '') => {
  if (value.trim().length < 1) {
    return 'Required';
  }

  return true;
};
