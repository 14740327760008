import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { useAuth0, useModel } from 'src/lib/hooks';
import { InactivityModal } from 'src/common';

const defaultOptions = {
  secured: true,
};

const withSession = (Component, options = defaultOptions) => {
  const secured = options.secured ?? defaultOptions.secured;

  const Page = (props) => {
    const history = useHistory();
    const { user, refreshSession, logout } = useAuth0();
    const updated = useModel.updated();
    const fnol = useModel.fnol();
    const [modalVisible, setModalVisible] = useState(false);

    // handle token and session expiration
    useEffect(
      () => {
        let logoutTimeoutId;
        let showModalTimeoutId;
        let mounted = true;

        if (user?.expiresIn > 0 && mounted) {
          const tokenExpiration = Math.min(
            Math.max(15, user?.sessionTimeout - 60),
            Math.max(15, user?.expiresIn - 60)
          );
          showModalTimeoutId = setTimeout(
            () => setModalVisible(true),
            tokenExpiration * 1000
          );
          logoutTimeoutId = setTimeout(() => {
            const route =
              !fnol || fnol.status !== 'In Progress'
                ? '/'
                : `/resume?email=${user?.email}&fnolId=${fnol?.fnolId}`;
            logout(route, {
              expired: true,
            });
          }, user?.sessionTimeout);
        }
        return () => {
          clearTimeout(showModalTimeoutId);
          clearTimeout(logoutTimeoutId);
          mounted = false;
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        user?.expiresIn,
        user?.token,
        logout,
        setModalVisible,
        user?.sessionTimeout,
      ]
    );

    useLayoutEffect(() => {
      const check = async () => {
        if (!user) {
          if (secured) {
            history.replace('/');
          }
        } else if (
          dayjs(updated.user.save).add(user.sessionTimeout, 'ms').isBefore()
        ) {
          logout();
        } else if (user.hasSession && !user.loggingIn) {
          await refreshSession();
        }
      };
      check();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const continueSession = async () => {
      if (user) {
        await refreshSession();
        setModalVisible(false);
      }
    };

    return !user && secured ? (
      <></>
    ) : (
      <>
        <Component {...props} />
        <InactivityModal
          modalVisible={modalVisible}
          onContinue={continueSession}
        />
      </>
    );
  };

  Page.displayName = Component.displayName;

  return Page;
};

export default withSession;
