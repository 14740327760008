import React from 'react';
import { Button, Checkbox, FilePreview, Form, Page, Error } from 'src/common';
import analytics, { EVENTS } from 'src/lib/analytics';
import { useModel, useForm, useFeatureFlags } from 'src/lib/hooks';
import Details from './Details';

import styles from './Review.module.css';

const Review = ({ prevPage, nextPage }) => {
  const fnol = useModel.fnol();
  const { enableAdditionalCols } = useFeatureFlags();
  const { submit } = useModel.fnol.dispatch();
  const {
    typeOfLoss,
    causeOfLoss,
    damages,
    preferredLanguage,
    attachments,
    areTranslationServicesNeeded,
    loss,
  } = fnol;

  const {
    register,
    handleSubmit,
    watch,
    formError,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = handleSubmit(async () => {
    await submit();
    analytics.track(EVENTS.CLAIM_SUBMITTED, fnol);
    await nextPage(null, { skipUpdate: true });
  });

  const understandStatement = watch('understandStatement');

  return (
    <Page
      stage="Almost there..."
      subtitle="If you are ready to send the information to us for review, click “Submit 
    Claim”. Alternatively, you may use the “Go Back” button to return to any of
    the previous steps and add information, photographs, or videos."
    >
      <div className={styles.sections}>
        <Page.Section title="Tell Us About Your Incident">
          <Details>
            <Details.Row>
              <Details.Item label="Incident Type" value={typeOfLoss} />
              <Details.Item label="Cause of Incident" value={causeOfLoss} />
            </Details.Row>
            <Details.Row>
              <Details.Item
                label="Incident Address"
                value={`${loss.address.street}, ${loss.address.city}, ${loss.address.state} ${loss.address.zip}`}
              />
              <Details.Item label="Incident Location" value={loss?.location} />
            </Details.Row>
            <Details.Item
              label="Description of Incident"
              value={loss?.description || 'None'}
            />
          </Details>
        </Page.Section>

        <Page.Section title="Tell Us About the Damages">
          {!!damages ? (
            <Details>
              {damages?.isEmergencyTarpingNeeded && (
                <Details.Item
                  label="Do you need emergency tarping?"
                  value={damages?.isEmergencyTarpingNeeded}
                />
              )}
              {damages?.isEmergencyBoardUpNeeded && (
                <Details.Item
                  label="Do you need emergency board up?"
                  value={damages?.isEmergencyBoardUpNeeded}
                />
              )}
              {damages?.haveInteriorDamages && (
                <Details.Item
                  label="Do you have interior damage?"
                  value={damages?.haveInteriorDamages}
                />
              )}
              {damages?.areWallsFloorsWet && (
                <Details.Item
                  label="Are the walls or floors wet?"
                  value={damages?.areWallsFloorsWet}
                />
              )}
              {damages?.wasWaterMitigationCompanyContacted && (
                <Details.Item
                  label="Has a water mitigation company already been contacted?"
                  value={damages?.wasWaterMitigationCompanyContacted}
                />
              )}
              {damages?.whoReferredProvider && (
                <Details.Item
                  label="Who referred you to this provider?"
                  value={damages.whoReferredProvider}
                />
              )}
              {damages?.areEmergencyWaterRemovalServicesNeeded && (
                <Details.Item
                  label="Do you need emergency water removal or drying services?"
                  value={damages?.areEmergencyWaterRemovalServicesNeeded}
                />
              )}
              {damages?.numberOfRoomsDamaged && (
                <Details.Item
                  label="How many rooms were damaged?"
                  value={damages?.numberOfRoomsDamaged}
                />
              )}
              {damages?.canPropertyBeOccupied && (
                <Details.Item
                  label="Can the property be occupied?"
                  value={damages?.canPropertyBeOccupied}
                />
              )}
              {damages?.isHotelAccommodationNeeded && (
                <Details.Item
                  label="Is a hotel accommodation needed?"
                  value={damages?.isHotelAccommodationNeeded}
                />
              )}
              {damages?.wasSystemOrApplianceMalfunction && (
                <Details.Item
                  label="Was the loss the result of a system or appliance malfunction?"
                  value={damages?.wasSystemOrApplianceMalfunction}
                />
              )}
              {damages?.howOldIsTheSystemMalfunctioned && (
                <Details.Item
                  label="How old is the system or appliance that malfunctioned?"
                  value={damages?.howOldIsTheSystemMalfunctioned}
                />
              )}
              {damages?.wasAnyoneElsePropertyDamaged && (
                <Details.Item
                  label="Was anyone else's property damaged?"
                  value={damages?.wasAnyoneElsePropertyDamaged}
                />
              )}
              {!!enableAdditionalCols && (
                <>
                  {damages?.wasAnybodyInjured && (
                    <Details.Item
                      label="Was anybody injured?"
                      value={damages?.wasAnybodyInjured}
                    />
                  )}
                  {damages?.wasThisAFatality && (
                    <Details.Item
                      label="Was this a fatality?"
                      value={damages?.wasThisAFatality}
                    />
                  )}
                  {damages?.wasInjuredAMinor && (
                    <Details.Item
                      label="Was the person injured a minor?"
                      value={damages?.wasInjuredAMinor}
                    />
                  )}
                  {damages?.injuriesDescription && (
                    <Details.Item
                      label="Describe the injuries."
                      value={damages?.injuriesDescription}
                    />
                  )}
                </>
              )}
              {(damages?.additionalComments ||
                damages?.additionalComments === '') && (
                <Details.Item
                  label="Additional Comments"
                  value={damages?.additionalComments || 'None'}
                />
              )}
            </Details>
          ) : (
            'No additional information provided.'
          )}
        </Page.Section>
        <Page.Section title="Photos, Videos and Documents">
          {attachments?.length > 0 ? (
            <FilePreview files={attachments} />
          ) : (
            'None'
          )}
        </Page.Section>
        <Page.Section title="Preferred Language">
          <Details>
            <Details.Item
              label="Do you need translation services to effectively communicate with your adjuster?"
              value={areTranslationServicesNeeded}
            />
            <Details.Item
              label="Preferred Language"
              value={preferredLanguage}
            />
          </Details>
        </Page.Section>
        <Page.Section>
          {formError && <Error>{formError}</Error>}
          <div className={styles.information}>
            <p>
              I acknowledge that I have read and answered all questions within
              this online claims submission.
            </p>
            <p>
              FRAUD STATEMENT: ANY PERSON WHO KNOWINGLY AND WITH THE INTENT TO
              INJURE, DEFRAUD, OR DECEIVE ANY INSURER FILES A STATEMENT OF CLAIM
              OR AN APPLICATION CONTAINING ANY FALSE, INCOMPLETE, OR MISLEADING
              INFORMATION IS GUILTY OF A FELONY OF THE THIRD DEGREE.
            </p>
          </div>
          <Form>
            <div className={styles.checkbox}>
              <Checkbox
                label="I have read and understand the above statement."
                {...register('understandStatement', { required: 'Required' })}
                error={errors?.understandStatement?.message}
              />
            </div>
          </Form>
        </Page.Section>
      </div>
      <Page.Buttons>
        <Button
          type="button"
          color="water"
          onClick={prevPage}
          disabled={isSubmitting}
        >
          Go Back
        </Button>
        <Button
          color="navy"
          onClick={onSubmit}
          disabled={!understandStatement}
          loading={isSubmitting}
        >
          Submit Claim
        </Button>
      </Page.Buttons>
    </Page>
  );
};

Review.displayName = 'Review';
export default Review;
