import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Error, Loading, Page } from 'src/common';
import {
  useAuth0,
  useModel,
  useQueryParams,
  useFeatureFlags,
} from 'src/lib/hooks';
import analytics from 'src/lib/analytics';
import EmailVerification from 'src/pages/EmailVerification';
import AuthMethods from './AuthMethods';
import ResumeMultiple from './ResumeMultiple';
import { useErrorHandling } from 'src/lib/hooks';

const Resume = () => {
  const { email, fnolId } = useQueryParams();
  const [fnols, setFnols] = useState([]);
  const { setSession } = useModel.session.dispatch();
  const { resume, getFnols } = useModel.fnol.dispatch();
  const { startLogin, user, handleStartLoginError, logout } = useAuth0();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [resumeMultiple, setResumeMultiple] = useState(false);
  const handleError = useErrorHandling();
  const [error, setError] = useState(null);
  const [relogin, setRelogin] = useState(false);
  const { enableFnolSelectionForResumption } = useFeatureFlags();

  useEffect(() => {
    analytics.page('Resume');
    setSession({ fnolId });
    const handleResumption = async () => {
      try {
        if (
          !user?.hasSession &&
          email &&
          fnolId &&
          !!history?.location?.state?.expired &&
          !relogin
        ) {
          return setRelogin(true);
        }

        if (user?.hasSession) {
          if (enableFnolSelectionForResumption && !fnolId) {
            setFnols(await getFnols());
            setResumeMultiple(true);
          } else if (fnolId) {
            await resume();
            history.replace('/submit');
          } else {
            setError('We were unable to resume your claim.');
          }
        } else {
          if (
            (email && fnolId) ||
            (email && enableFnolSelectionForResumption && !resumeMultiple)
          ) {
            await startLogin(email);
          } else if (!enableFnolSelectionForResumption) {
            setError('We were unable to resume your claim.');
          }
        }
      } catch (err) {
        if (err?.statusCode) {
          setError(await handleStartLoginError(err));
        } else if (
          err?.invalidFnolStatus === 'Submitted' ||
          err?.invalidFnolStatus === 'Open' ||
          err?.invalidFnolStatus === 'Exhausted' ||
          err?.invalidFnolStatus === 'Failure'
        ) {
          setError(
            'It appears this claim has already been submitted. A representative from our claims department will contact you soon.'
          );
        } else if (err?.invalidFnolStatus === 'Abandoned') {
          setError(
            'It appears the claim you were trying to submit expired due to inactivity. Please start the claim submission process to report your claim.'
          );
        } else if (err?.invalidFnolStatus === 'Cancelled') {
          setError(
            'You previously cancelled this unsubmitted claim notification, and it cannot be resumed. Please start a new claim to continue.'
          );
        } else {
          setError(await handleError(err));
        }
      } finally {
        setLoading(false);
      }
    };
    handleResumption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartNewClaim = () => {
    logout();
  };

  const handleContinue = async (formEmail) => {
    await startLogin(formEmail);
    setRelogin(false);
  };

  const handleSelectedResumption = async (fnolId) => {
    setSession({ fnolId });
    await resume();
    history.replace('/submit');
  };

  if (loading) {
    return <Loading />;
  }

  if (!!error) {
    return (
      <Page title="Resume Your Submission">
        <Page.Section>
          <Error>{error}</Error>
        </Page.Section>
        <Page.Buttons>
          <Button color="navy" onClick={handleStartNewClaim} type="submit">
            Start New Claim
          </Button>
        </Page.Buttons>
      </Page>
    );
  }

  if (enableFnolSelectionForResumption) {
    return resumeMultiple ? (
      <ResumeMultiple
        handleSelectedResumption={handleSelectedResumption}
        handleStartNewClaim={handleStartNewClaim}
        fnols={fnols}
      />
    ) : (
      <AuthMethods
        handleContinue={handleContinue}
        handleStartNewClaim={handleStartNewClaim}
      />
    );
  }
  return (
    <Page title="Resume Your Submission">
      {relogin && !user?.hasSession ? (
        <>
          <Page.Section>
            Your session has expired. Please verify your email to resume your
            submission.
          </Page.Section>
          <Page.Buttons>
            <Button
              color="navy"
              onClick={() => handleContinue(email)}
              type="submit"
            >
              Continue
            </Button>
          </Page.Buttons>
        </>
      ) : (
        <EmailVerification.Resumption
          onBack={handleStartNewClaim}
          backTitle="Start New Claim"
        />
      )}
    </Page>
  );
};

export default Resume;
