import React from 'react';
import { classnames } from 'tailwindcss-classnames';

import styles from './ProgressBar.module.css';

const stages = [
  'Policy Verification',
  'Contact Info',
  'Tell Us About Your Incident',
  'Tell Us About the Damages',
  'Photos, Videos, and Documents',
  'Preferred Language',
  'Almost there...',
  'We Have Received Your Information',
];

const ProgressBar = ({ stage }) => {
  const progress = stages.findIndex((s) => s === stage);

  if (progress < 0) {
    return <div data-testid="no-stage" />;
  }

  return (
    <div className={styles.container}>
      <h1>{stage}</h1>
      <div className={styles.bar}>
        <span className="sr-only">
          {progress + 1 === stages.length
            ? 'complete'
            : `${progress + 1} of ${stages.length} steps`}
        </span>
        {stages.map((_, i) => (
          <div
            key={i}
            className={classnames('flex-grow', {
              [styles.filled]: progress >= i,
            })}
          />
        ))}
      </div>
    </div>
  );
};

ProgressBar.stages = stages;

export default ProgressBar;
