import React from 'react';
import dayjs from 'dayjs';

import styles from './Footer.module.css';

import { upcLogoWhite } from 'src/assets/img';
import Button from '../Button';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.divider}>
        <div className={styles.message}>
          Content provided is intended for general informational purposes only.
          Any changes made to your policy may not be immediately reflected here.
          Please refer to your insurance policy for coverages, exclusions, and
          limitations.
        </div>
      </div>
      <div className={styles.logo}>
        <a href="#/">
          <img
            src={upcLogoWhite}
            alt="Insurance logo"
            id="logo"
            className={styles.logoContainer}
          />
        </a>
        <p className={styles.copy}>
          © {dayjs().year()}, Lorem Ipsum. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
