import React from 'react';
import { Button, Page } from 'src/common';
import styles from './Submitted.module.css';

const Submitted = () => {
  return (
    <Page stage="We Have Received Your Information">
      <Page.Section>
        <div className={styles.information}>
          <p>Thank you!</p>
          <p>Your information has been received and is being reviewed.</p>
          <p>
            A claims adjuster will contact you as soon as possible. You will
            receive an email soon that will contain your claim handler’s name
            and phone number. We are generally able to contact our insureds
            within one business day. However, if you have submitted a claim as a
            result of a recent hurricane or tropical storm, it may take longer
            for us to respond due to the volume of claims being reported.
          </p>
          <p>
            You may now close this tab or use the button to visit Slide's Claims
            Center for more resources.
          </p>
        </div>
      </Page.Section>
      <Page.Buttons>
        <Button
          color="water"
          href="https://www.slideinsurance.com/claimscenter"
        >
          Slide Claims Center
        </Button>
      </Page.Buttons>
    </Page>
  );
};

Submitted.displayName = 'Submitted';

export default Submitted;
