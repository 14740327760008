import React from 'react';
import { Page } from 'src/common';

const MaintenanceMode = () => (
  <Page title="We're Undergoing Maintenance">
    <Page.Section>
      We are currently undergoing scheduled maintenance. Please try again later
      or call the UPC Insurance Customer Experience Center (CEC) at (800)
      557-0964 for assistance.
    </Page.Section>
  </Page>
);

export default MaintenanceMode;
