import React, { useEffect } from 'react';
import { Button, Page, Form } from 'src/common';
import Select from 'src/common/Select';
import config from 'src/config';
import { lossReporterRoles } from 'src/lib/constants';
import { useModel, useForm } from 'src/lib/hooks';
import AboutYourself from '../AboutYourself';
import EmailVerification from '../EmailVerification';

import styles from './WhoIsReporting.module.css';

const WhoIsReporting = ({ nextPage }) => {
  const { setPendingChanges, skip } = useModel.session.dispatch();
  const { pendingChanges } = useModel.session();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: pendingChanges,
  });

  const lossReporter = watch('loss.reportedBy.participantRole');

  useEffect(() => {
    skip({
      [AboutYourself.displayName]: lossReporter === 'Policyholder',
      [EmailVerification.Representative.displayName]:
        lossReporter === 'Policyholder',
      [EmailVerification.PolicyHolder.displayName]:
        lossReporter !== 'Policyholder',
    });
  }, [lossReporter, skip]);

  const onSubmit = handleSubmit(async (data) => {
    setPendingChanges(data);

    await nextPage();
  });

  return (
    <Page stage="Let's Get Started">
      <Page.Section>
        <Form onSubmit={onSubmit}>
          <Select
            label="Who is reporting the Loss?"
            options={lossReporterRoles}
            {...register('loss.reportedBy.participantRole', {
              required: 'Required',
            })}
            error={errors?.loss?.reportedBy?.participantRole?.message}
          />
          {lossReporter === 'Policyholder' && (
            <>
              <p className={styles.policyHolderText}>
                Do you have a{' '}
                <span className={styles.policyHolderLink}>
                  <a href={`${config.phc.baseUrl}/login`}>
                    Policyholder Center account
                  </a>
                </span>
                ?
                <br /> Click the link and you can file your claim from your
                Policyholder account.
              </p>
              <p className={styles.policyHolderText}>
                By logging into your account, your information will be
                autofilled and you will experience a more efficient submission
                process.
              </p>
            </>
          )}
        </Form>
      </Page.Section>
      <Page.Buttons>
        <Button
          color="navy"
          type="submit"
          disabled={!lossReporter}
          onClick={onSubmit}
          loading={isSubmitting}
        >
          Next Step
        </Button>
      </Page.Buttons>
    </Page>
  );
};

WhoIsReporting.displayName = 'WhoIsReporting';

export default WhoIsReporting;
